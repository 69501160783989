import React from 'react';
import { Img } from "react-image"

const LazyLoadingImage = (image) => {

    return (

        <Img
            alt={image.alt}
            src={image.src}
            loader={<div style={{ backgroundColor: '#f2f2f2', height: 'auto', width: '100%' }}></div>}
        />

    )
}

export default LazyLoadingImage;
