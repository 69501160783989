import React, { useState } from 'react';
import "./Header.css"
import { useNavigate } from "react-router-dom";
import { Link, scroller } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';

/**** ICON ****/
import { AiFillCloseCircle } from "react-icons/ai";

/***** IMG *****/
import GooglePlay from "../assets/images/btn/playstore_btn.png"
import AppStore from "../assets/images/btn/appstore_btn.png"
import QRCode from "../assets/images/qr-code.png"

const Header = () => {
    const [isVisible, setIsVisible] = useState(false)
    const navigate = useNavigate();

    // close modal
    const handleClose = () => {
        setIsVisible(false);
    };

    const redirectAppStore = async () => {

        if (isMobile) {
            window.location.href = "https://apps.apple.com/fr/app/sharlock/id1633063767"
        }
        else {
            window.open("https://apps.apple.com/fr/app/sharlock/id1633063767", '_blank').focus();
        }

    }

    const redirectPlayStore = async () => {

        if (isMobile) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.sharlock.android"
        }
        else {
            window.open("https://play.google.com/store/apps/details?id=com.sharlock.android", '_blank').focus();
        }

    }

    const handleScroll = (to) => {
        const element = document.getElementById(to);

        if (element) {
            scroller.scrollTo(to, {
                smooth: true,
                duration: 500,
                offset: 0, // adjust the offset as needed
                spy: {
                    callback: (currentPosition) => {
                        if (currentPosition === null) {
                            
                        }
                    },
                },
            });
        } else {
            const data = { anchor: "contact" };
            navigate("/", { state: data });
        }
    }


    return (
        <div className="header">
            <Modal
                open={isVisible}
                onClose={handleClose}
                aria-labelledby="Télécharger Sharlock"
                aria-describedby="Flash le qrcode ou clic sur les boutons du store pour télécharger l'application mobile"
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className="modal-wrapper">

                            <AiFillCloseCircle
                                className="modal-close-icon"
                                onClick={handleClose}
                            />

                            <p className="main-title" style={{ color: "white" }}>Téléchargez <span className="whitespan">Sharlock</span>
                            </p>

                            <div className="store-buttons-container">
                                <div className="store-button" onClick={redirectAppStore}>
                                    <img src={AppStore} alt="App Store" />
                                </div>

                                <div className="store-button" onClick={redirectPlayStore}>
                                    <img src={GooglePlay} alt="Google Play" />
                                </div>
                            </div>

                            <p className="modal-text">ou flashe le QR code avec ton téléphone :</p>

                            <img src={QRCode} alt="qrcode application sharlock" className="modal-qrcode" />

                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <nav className="navbar">
                <div className="nav-left">
                    <div onClick={() => window.location.href = "/"}>
                        <h1 className="nav-title">Sharlock</h1>
                    </div>
                    <button onClick={() => setIsVisible(true)} className="nav-download-app-btn">Télécharger l’app</button>
                </div>

                <div className="nav-right">
                    <Link
                        activeClass="active"
                        to={"contact"}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={1000}
                        className="nav-collaborate-btn"
                        onClick={() => handleScroll("contact")}
                    >Marque : Collaborer</Link>
                    <a href="https://sharlockpro.fr" target="blank" className="nav-link">Accéder à mon espace pro</a>
                </div>
            </nav>

        </div>
    );
}

export default Header;
