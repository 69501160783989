import React from 'react';
import Marquee from "react-fast-marquee";

const CategoriesBanner = () => {

    const categories = [
        {
            category: "MODE",
            font: "Soopafresh"
        },
        {
            category: "BEAUTÉ",
            font: "Tabardo"
        },
        {
            category: "COSMÉTIQUE",
            font: "Baloo"
        },
        {
            category: "HYGIÈNE",
            font: "Tabardo"
        },
        {
            category: "ACCESSOIRE",
            font: "Soopafresh"
        },
        {
            category: "BIJOUX",
            font: "Baloo"
        },
        {
            category: "DÉCO",
            font: "Tabardo"
        },
        {
            category: "SPORT",
            font: "Soopafresh"
        },
        {
            category: "SNEAKERS",
            font: "Tabardo"
        },
        {
            category: "Enfant",
            font: "Tabardo"
        },
        {
            category: "MODE",
            font: "Soopafresh"
        },
        {
            category: "BEAUTÉ",
            font: "Tabardo"
        },
        {
            category: "COSMÉTIQUE",
            font: "Baloo"
        },
        {
            category: "HYGIÈNE",
            font: "Tabardo"
        },
        {
            category: "ACCESSOIRE",
            font: "Soopafresh"
        },
        {
            category: "BIJOUX",
            font: "Baloo"
        },
        {
            category: "DÉCO",
            font: "Tabardo"
        },
        {
            category: "SPORT",
            font: "Soopafresh"
        },
        {
            category: "SNEAKERS",
            font: "Tabardo"
        },
        {
            category: "Enfant",
            font: "Tabardo"
        },


    ]

    return (
        <div className="category-banner">
            <Marquee
                className="category-names"
                speed={150}
                direction="left"
                gradientWidth={0}
            >
                {categories.map((item, index) => (
                    <span style={{ fontFamily: item.font }} key={index}>
                        {item.category}
                    </span>
                ))}
            </Marquee>

        </div>
    );
}

export default CategoriesBanner;


