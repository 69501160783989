import React, { useEffect } from "react"
import "./Home.css"
import { useLocation } from "react-router";
import Helmet from 'react-helmet';

import HomeHeader from "./HomeHeader"
import SliderLogo from "./SliderLogo"
import Environmentally from "./Environmentally"
import CategoriesBanner from "./CategoriesBanner"
import FindMyWayAmongGems from "./FindMyWayAmongGems"
import StayInform from "./StayInform"
import Rating from "./Rating"
import Instagram from "./Instagram"
import ContactUs from "./ContactUs"

const Home = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Helmet>
                <title>Sharlock</title>
                <meta name='description' content="Trouver des marques engagées n’a jamais été aussi simple. L’app qui réunit les pépites françaises qui respectent la planète 🌎" />
            </Helmet>

            <HomeHeader />

            <SliderLogo />

            <Environmentally />

            <CategoriesBanner />

            <FindMyWayAmongGems />

            <StayInform />

            <Rating />

            <Instagram />

            <ContactUs />
        </>
    );
}

export default Home;
