import React from "react";
import LazyLoadImage from "../components/LazyLoadingImage"

const Instagram = () => {

    const galleries = [
        {
            id: 1,
            img: require("../assets/images/instagram/gallery-1.png")
        },
        {
            id: 2,
            img: require("../assets/images/instagram/gallery-2.png")
        },
        {
            id: 3,
            img: require("../assets/images/instagram/gallery-3.png")
        },
        {
            id: 4,
            img: require("../assets/images/instagram/gallery-4.png")
        },
        {
            id: 5,
            img: require("../assets/images/instagram/gallery-5.png")
        },
        {
            id: 6,
            img: require("../assets/images/instagram/gallery-6.png")
        },
        {
            id: 7,
            img: require("../assets/images/instagram/gallery-7.png")
        },
        {
            id: 8,
            img: require("../assets/images/instagram/gallery-8.png")
        }
    ]

    return (
        <div className="instagram-container">
            <div className="instagram-content">
                <div className="instagram-header">
                    <h2 className="main-title">Rejoins nous sur <span className="orangespan">instagram</span></h2>
                    <button onClick={() => window.open("https://www.instagram.com/sharlock_app/", "_blank")} className="follow-us">Nous suivre</button>
                </div>

                <p className="second-text">Des SHAR’LOOK, des JEUX CONCOURS, DES ACTUS et beaucoup de FUN</p>

                <div className="instagram-gallery">
                    {galleries.map((gallery) => (
                        <div key={gallery.id} className="gallery-card">

                            <LazyLoadImage
                                alt={"Post instagram " + gallery.id}
                                src={gallery.img}
                            />

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Instagram;
