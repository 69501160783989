import React, { useEffect } from "react"
import "./Rgpd.css"
import { useLocation } from "react-router";
import Helmet from 'react-helmet';

const Cgu = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Helmet>
                <title>Sharlock - Mention Légales et CGU</title>
                <meta name='description' content="Mentions légales et conditions générales d'utilisation" />
            </Helmet>

            <header className="rgpd_header">
                <h1 className="rgpb_header_title">Mention Légales et CGU</h1>
            </header>

            <main className="rgpd_container">
                <h2>Application Sharlock</h2>
                <p>La simple utilisation de l’Application SHARLOCK (ci-après « l’Application ») vaut acceptation, sans réserve, des stipulations et conditions d’utilisation qui suivent et des avis qui y sont contenus.</p>
                <p>Tout Utilisateur qui utilise l’Application au nom d’une organisation commerciale, d’un institut de recherche ou d’une entité garantit être dûment autorisé à utiliser l’Application pour le compte de cette organisation commerciale, de cet institut de recherche ou de toute autre entité.</p>
                <p>La version actuelle en ligne de ces conditions d’utilisation est la seule opposable pendant toute la durée d’utilisation de l’Application et jusqu’à ce qu’une nouvelle version la remplace. Les conditions générales d’utilisation (ci-après « Conditions Générales d’Utilisation » ou « CGU ») revêtent un caractère indivisible et leur acceptation vaut pour l’ensemble, exprimée en une seule fois. Les Utilisateurs ne peuvent en aucun cas renoncer unilatéralement à l’application d’une ou plusieurs des clauses des présentes, annexes comprises. Elles s’appliquent totalement et exclusivement, à l’exception des dispositions impératives du droit en vigueur.
                </p>
                <h2>Préambule</h2>
                <p>Application : SHARLOCK</p>
                <p>Éditeur (ci-après désigné par « l’Éditeur ») : TBM – SAS au capital de 1.000 €, dont le siège social est 2058 chemin de Méginand – 69160 TASSIN LA DEMI-LUNE.</p>
                <p>Adresse de courrier électronique : <a href="mailto:sharlock.contact@gmail.com">sharlock.contact@gmail.com</a></p>
                <p>L’application SHARLOCK démocratise le commerce électronique en rendant le check-out en ligne facile, fiable et cohérent pour les cybers-acheteurs. Vous avez ensuite la possibilité de créer un compte ce qui vous permet d'accéder au réseau SHARLOCK, qui contient ses marques partenaires.</p>

                <h2>Modification des Conditions Générales d’Utilisation</h2>

                <p>L’Éditeur se réserve le droit de modifier les termes, conditions et avis en vertu desquels l’Application SHARLOCK est proposée, y compris, mais sans s’y limiter, les frais associés à l’utilisation de l’Application mobile SHARLOCK. Les modifications entrent en vigueur dès la publication d’une version mise à jour de ces Conditions Générales d’Utilisation sur Sharlock.fr. L’utilisation continue de l’Application SHARLOCK après de telles modifications des Conditions Générales d’Utilisation vaudra consentement à ces modifications.</p>

                <h2>Utilisation et Accès à l’Application</h2>

                <h3>Utilisateurs</h3>

                <p>L’Application est accessible gratuitement aux utilisateurs.</p>
                <p> L’Éditeur se réserve le droit de demander toute justification de l’âge de l’Utilisateur.</p>
                <p>En utilisant l’Application, l’Utilisateur est responsable de l’ensemble de son activité.</p>
                <p>
                    L’Utilisateur garantit :
                </p>
                <ul style={{ paddingLeft: "0.9rem" }}>
                    <li>Être un humain, et non un service informatique ou un « robot » automatisé</li>
                    <li>Avoir la capacité de conclure un contrat ayant force obligatoire avec l’Éditeur</li>
                    <li>Fournir les informations exactes et valides demandées lors du processus d’inscription</li>
                    <li>Utiliser l’Application uniquement à des fins légales dans les conditions définies ci-après</li>
                    <li>Respecter ces CGU et tous les règlements, règles et lois applicables au niveau local, régional, national ou international.</li>
                </ul>
                <p>L’utilisation de l’Application par un Utilisateur ne respectant pas ces conditions, depuis le compte d’un Utilisateur respectant ces conditions, se fera sous la responsabilité pleine et entière de ce dernier.</p>

                <h3>Destination de l’Application</h3>

                <p>L’accès à l’Application et son utilisation sont réservés à un usage strictement personnel, dans le but défini en Préambule.</p>
                <p>L’Utilisateur s’engage à ne pas utiliser l’Application et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires ou pour toute forme de sollicitation commerciale et notamment l’envoi de messages électroniques non sollicités.</p>
                <p>Il est rappelé que l’utilisation de l’Application est gratuite ; les frais supplémentaires supportés par l’Utilisateur du fait de l’utilisation de l’Application, et imputable à d’autres prestataires, ne peuvent être d’aucune manière reprochés ou supportés par l’Éditeur.</p>

                <h3>Inscription et numéro de téléphone</h3>

                <h4>Inscription</h4>
                <p>Après téléchargement de l’Application et lors de son inscription, l’Utilisateur devra renseigner les champs obligatoires demandés (nom, prénom, âge, adresse électronique, numéro de téléphone). Il s’engage à communiquer des informations exactes et sincères. En cas de modification des informations fournies, l’Utilisateur s’engage à modifier les données enregistrées dans son compte client.</p>
                <p>Il autorisera ou refusera également l’envoi de notification push lui permettant notamment d’être tenu au courant de l’ajout d’une marque à ses sites et de la parution d’une nouvelle offre le concernant.</p>

                <h4>Modification</h4>
                <p>L’Utilisateur pourra modifier ses nom, prénom, date de naissance, numéro de téléphone, adresse électronique, adresse postale et adresse de facturation au sein de son espace profil accessible depuis l’Application.</p>

                <h4>Utilisation – Confidentialité</h4>
                <p>L’Utilisateur est seul responsable de son numéro de téléphone.</p>
                <p>L’Utilisateur devra informer sans délai l’Éditeur s’il constate une faille de sécurité liée notamment à la perte ou au vol de son téléphone portable, afin que l’Éditeur puisse prendre sans délai toute mesure adaptée en vue de faire remédier à la faille de sécurité.</p>
                <p>En cas de perte ou de détournement d’un téléphone, une procédure d’attribution de nouveaux identifiants est mise en œuvre.</p>

                <h2>Disponibilité</h2>

                <p>L’Éditeur fait son possible afin de permettre à l’Utilisateur un accès à l’Application 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure et sous réserve de ce qui suit.</p>
                <p>L’Éditeur pourra, notamment, à tout moment, sans que sa responsabilité ne puisse être engagée :</p>
                <ul style={{ paddingLeft: "0.9rem" }}>
                    <li>Suspendre, interrompre ou limiter l’accès à tout ou partie de l’Application, réserver l’accès à l’Application, ou certaines parties de l’Application, à une catégorie déterminée d’Utilisateurs</li>
                    <li>Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales, ou avec les règles de la Nétiquette</li>
                    <li>Suspendre ou limiter l’accès à l’Application afin de procéder à des mises à jour.</li>
                </ul>
                <p>L’Éditeur est dégagé de toute responsabilité en cas d’impossibilité d’accès à l’Application du fait d’un évènement échappant à son contrôle (notamment problème sur le serveur de l’Utilisateur, aléas techniques, perturbation sur le réseau internet …).</p>
                <p>L’Utilisateur reconnait que l’obligation de l’Éditeur quant à la disponibilité de l’Application est une simple obligation de moyens.</p>

                <h2>Utilisation légale de l’Application</h2>

                <p>L’Utilisateur garantit qu’il n’utilisera pas l’Application à des fins illégales ou interdites par les présentes Conditions Générales d’Utilisation.</p>
                <p>L’Utilisateur s’interdira d’utiliser l’Application d’une manière qui pourrait endommager, désactiver, surcharger ou altérer l’Application SHARLOCK ou interférer avec l’Utilisation et la jouissance de SHARLOCK par une autre partie. Il est interdit à l’Utilisateur d’obtenir ou tenter d’obtenir des matériaux ou des informations par des moyens qui ne sont pas intentionnellement mis à disposition ou fournis via l’Application mobile SHARLOCK.</p>
                <p>
                    L’Utilisateur reconnaît, en utilisant l’Application mobile SHARLOCK, qu’il ne pourra pas (et n’autoriserez pas un tiers à le faire) notamment :
                </p>
                <ul style={{ paddingLeft: "0.9rem" }}>
                    <li>Violer ou tenter de violer la sécurité de l’Application mobile SHARLOCK
                    </li>
                    <li>Reproduire, dupliquer, copier, vendre, revendre ou exploiter toute partie de l’Application mobile SHARLOCK, utiliser l’Application mobile SHARLOCK ou accéder à l’Application SHARLOCK sans l’autorisation écrite expresse de SHARLOCK
                    </li>
                    <li>Diffamer, abuser, harceler, traquer, menacer ou autrement violer les droits légaux d’autrui</li>
                    <li>Publier, télécharger, distribuer ou diffuser tout sujet, nom, matériel ou information inapproprié, profane, diffamatoire, contrefait, obscène, indécent ou illégal</li>
                    <li>Soit a) entreprendre une action ou b) télécharger, publier, soumettre ou autrement distribuer ou faciliter la distribution de tout contenu sur ou via l’Application mobile SHARLOCK, y compris, sans limitation et utiliser le contenu, qui :</li>
                </ul>
                <ul style={{ paddingLeft: "0.9rem" }}>
                    <li>Viole tout brevet, marque déposée, secret commercial, droit d’auteur, droit de publicité ou autre droit de toute autre personne ou entité ou enfreint toute loi ou obligation contractuelle</li>
                    <li>Est faux, trompeur, mensonger ou inexact</li>
                    <li>Est illégal, menaçant, abusif, harcelant, diffamatoire, calomnieux, trompeur, frauduleux, envahissant la vie privée d’autrui, nuit autrement à un tiers, délictueux, obscène, vulgaire, pornographique, offensant, profane, contient ou décrit de la nudité, contient ou décrit une activité sexuelle, ou est autrement inapproprié tel que déterminé à la seule discrétion de l’Éditeur</li>
                    <li>Constitue de la publicité non autorisée ou non sollicitée, du courrier indésirable ou en masse (spam)</li>
                    <li>Contient des virus logiciels ou tout autre code informatique, fichier ou programme conçu ou destiné à perturber, endommager, limiter ou interférer avec le bon fonctionnement de tout logiciel, matériel ou équipement de télécommunications ou pour endommager ou obtenir un accès non autorisé à tout système, données, ou autres informations de l’Application ou de tout tiers</li>
                    <li>Usurpe l’identité de toute personne ou entité, y compris l’un de des représentants de l’Éditeur</li>
                    <li>Comprend les documents d’identification de toute personne ou des informations financières sensibles</li>
                    <li>Vise à créer un site Web ou une application trompeuse pour impliquer une association avec l’Application mobile SHARLOCK</li>
                    <li>Vise à faire de la publicité ou proposer de vendre ou d’acheter des biens ou des services à des fins commerciales, sauf autorisation expresse de l’Éditeur</li>
                    <li>Vise à télécharger tout fichier publié par un autre Utilisateur de l’Application qui ne peut pas être légalement distribué de cette manière</li>
                    <li>Vise à restreindre ou empêcher tout autre Utilisateur d’utiliser et de profiter de l’Application mobile SHARLOCK</li>
                    <li>Viole tout code de conduite ou autres directives applicables</li>
                    <li>Vise à récolter ou collecter des informations sur les autres Utilisateurs, y compris les numéros téléphoniques, sans leur consentement</li>
                    <li>Viole les lois ou règlements applicables.</li>
                </ul>
                <p>Chaque Utilisateur reconnaît qu’il responsable de sa propre utilisation de l’Application mobile SHARLOCK et des conséquences de cette utilisation.</p>
                <p>En cas d’utilisation de l’Application contraire aux présentes CGU, l’Éditeur se réserve le droit de suspendre l’accès d’un Utilisateur à toute ou partie des services de l’Application SHARLOCK à tout moment et sans préavis, et ce, pour quelque raison que ce soit.</p>

                <h2>Responsabilité de l’Éditeur</h2>

                <p>La responsabilité de l’Éditeur ne pourra pas être recherchée notamment en cas :</p>
                <ul style={{ paddingLeft: "0.9rem" }}>
                    <li>D’utilisation par l’Utilisateur de l’Application contraire à son objet</li>
                    <li>En cas de survenance d’un dommage causé par un Utilisateur à un autre Utilisateur ou à un tiers dans le cadre d’une sortie pour la mise en place de laquelle l’Application est intervenue</li>
                    <li>Du fait du non-respect par l’Utilisateur des présentes Conditions Générales d’Utilisation</li>
                    <li>De coupure du réseau Internet et/ou intranet</li>
                    <li>De survenance de problèmes techniques et/ou d’une cyber-attaque affectant les locaux, les installations et espaces numériques, les logiciels, et le matériel appartenant à ou placés sous la responsabilité de l’Utilisateur</li>
                    <li>Par ailleurs, l’Éditeur n’est pas responsable des dommages causés à l’Utilisateur, à des tiers et/ou à l’équipement de l’Utilisateur du fait de sa connexion ou de son utilisation de l’Application.</li>
                </ul>
                <p>Si l’Éditeur venait à faire l’objet d’une procédure amiable ou judiciaire à raison de l’utilisation de l’Application par l’Utilisateur, il pourrait se retourner contre lui pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.
                </p>
                <p>Le matériel de connexion à l’Application est utilisé par l’Utilisateur sous son entière responsabilité.</p>
                <p>L’Utilisateur doit prendre toutes les mesures appropriées pour protéger son matériel et ses propres données notamment d’attaques virales par Internet.</p>
                <p>Les informations, logiciels, produits et services inclus dans ou disponibles via l’Application SHARLOCK peuvent inclure des inexactitudes ou des erreurs typographiques. Des modifications sont périodiquement ajoutées aux informations contenues dans ce document. L’Éditeur peut à tout moment apporter des améliorations et/ou des changements au flux de l’Application SHARLOCK.</p>
                <p>Les conseils reçus via l’Application ne doivent pas être invoqués pour des décisions personnelles, médicales, juridiques ou financières.</p>
                <p>L’Éditeur ne fait aucune déclaration quant à l’adéquation, la fiabilité, la disponibilité, l’actualité et l’exactitude des informations, logiciels, produits, services et graphiques associés contenus dans le flux de l’Application SHARLOCK à quelque fin que ce soit dans la mesure maximale autorisée par la loi applicable, toutes ces informations, logiciels , les produits, services et graphiques associés sont fournis «tels quels» sans garantie ni condition d’aucune sorte, sans préjudice des obligations résultant des activités en tant que sous-traitants. L’Éditeur décline par la présente toutes les garanties et conditions concernant ces informations, logiciels, produits, services et graphiques associés, y compris toutes les garanties ou conditions implicites de qualité marchande, d’adéquation à un usage particulier, de titre et de non-contrefaçon.</p>


                <h2>Liens vers des tiers</h2>

                <p>L’Application SHARLOCK utilise des fournisseurs tiers et des partenaires d’hébergement pour fournir le matériel, les logiciels, la mise en réseau, le stockage et les autres technologies nécessaires pour exécuter le service.
                    Toute information accessible via un lien vers d’autres sites n’est pas sous le contrôle de l’Éditeur qui décline toute responsabilité quant à leur contenu de même que dans le cas d’un quelconque problème technique et/ou de faille de sécurité provenant d’un lien vers un tiers.</p>

                <h2>Protection des données personnelles – Collecte des données</h2>

                <p>Le traitement et la protection des données personnelles de l’application SHARLOCK sont régis par les termes de la « Politique de confidentialité de l’Application SHARLOCK » et les dispositions du droit français, européen et international sur la protection des personnes physiques à l’égard du traitement des données personnelles, ainsi que les décisions des données françaises.</p>

                <h2>Modération / Restriction d’accès / Résiliation</h2>

                <p>Avant toute publication effective sur l’Application, l’Éditeur contrôlera les contenus qui y seront publiés. Il se réserve le droit de modérer ou de supprimer tout contenu ou toute information qui entrerait en infraction avec les présentes CGU ou la loi.</p>
                <p>L’Éditeur se réserve le droit, à sa seule discrétion, de mettre fin à l’accès de l’Utilisateur à l’Application SHARLOCK et aux services associés ou à toute partie de ceux-ci à tout moment, sans préavis. En particulier, l’Éditeur se réserve le droit de résilier l’accès d’un Utilisateur à l’application SHARLOCK et aux services associés lorsque ce dernier ne se conforme pas aux présentes CGU.</p>
                <p>L’Utilisateur peut, à tout moment et pour quelque raison fermer son compte SHARLOCK.</p>

                <h2>Cookies</h2>

                <p>La navigation opérationnelle sur l’Application peut rendre nécessaire l’utilisation de cookies, qui sont des mini-logiciels « traceurs » implantés sur le terminal de l’Utilisateur et qui donne accès à l’Éditeur à des informations de connexion standards.</p>
                <p>L’utilisation des cookies est présentée dans l’Annexe 2 des présentes CGU.</p>

                <h2>Droit applicable – Différends</h2>

                <p>De convention expresse entre les Parties, les présentes CGU sont soumises aux lois et aux procédures légales existantes de droit français. Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.</p>
                <p>
                    En cas de litige, toute réclamation doit être adressée à l’Éditeur par courrier ou par courriel aux adresses mentionnées aux présentes.</p>
                <p>À défaut de résolution amiable de tous les litiges auxquels les présentes CGU pourraient donner lieu, concernant tant leur validité, leur interprétation, leur exécution, leur résiliation, leurs conséquences ou leurs suites seront soumis aux tribunaux compétents de TASSIN LA DEMI-LUNE, France.</p>
                <p>L’Utilisateur reconnaît qu’aucune relation de coentreprise, de partenariat, d’emploi ou d’agence n’existe entre lui et l’Éditeur à la suite de l’utilisation de l’Application mobile SHARLOCK.</p>
                <p>Si une partie des CGU est jugée invalide ou inapplicable conformément à la loi applicable, y compris, mais sans s’y limiter, les exclusions de garantie et les limitations de responsabilité énoncées ci-dessus, alors la disposition invalide ou inapplicable sera réputée remplacée par une disposition valide et exécutoire qui correspond le mieux à l’intention de la disposition originale et le reste de l’accord restera en vigueur.</p>
                <p>Sauf indication contraire dans les présentes, les CGU constituent l’intégralité de l’accord entre l’Utilisateur et l’Éditeur en ce qui concerne l’Application SHARLOCK et il remplace toutes les communications et propositions antérieures ou contemporaines, qu’elles soient électroniques, orales ou écrites, entre l’Utilisateur et l’Éditeur en ce qui concerne l’Application mobile SHARLOCK.</p>
                <p>Une version imprimée du présent Contrat et de tout avis donné sous forme électronique sera admissible dans les procédures judiciaires ou administratives fondées sur les présentes CGU ou s’y rapportant.</p>

                <h2>Vie privée des enfants</h2>

                <p>Dans le cas où l’Utilisateur est un parent ou un tuteur légal et qu’il accepte les présentes CGU au nom d’un enfant de moins de 16 ans, il entend accepter de superviser son utilisation de l’Application et d’être entièrement responsable de toute responsabilité légale qu’une telle utilisation pourrait encourir ainsi que de toute information que l’enfant pourrait fournir.</p>
                <p>Dans le cas où un enfant de moins de 16 ans aurait fourni des informations personnelles sans autorisation préalable ou consentement parental, son compte sera supprimé.</p>
                <p>Pour toute information, veuillez nous contacter à <a href="mailto:sharlock.contact@gmail.com">sharlock.contact@gmail.com</a>.</p>

                <h2>Avis de droits d’auteur</h2>

                <p>L’Éditeur conserve tous les droits de propriété intellectuelle sur leurs services, contenus et logiciels respectifs.</p>
                <p>L’Utilisateur reconnait que l’Éditeur conserve la propriété de tous les droits, titres et intérêts relatifs à l’Application mobile SHARLOCK, à la conception et à la documentation de SHARLOCK, ainsi qu’aux droits de propriété intellectuelle y afférents (y compris, sans limitation, tous les droits de brevet, droits de conception, droits d’auteur et droits de secret commercial).</p>
                <p>L’Utilisateur accepte de ne pas copier, modifier ou désosser le matériel, les logiciels ou la conception de l’Application SHARLOCK, faire des travaux dérivés basés sur l’Application SHARLOCK, ou utiliser l‘Application SHARLOCK pour développer des services, sans l’approbation écrite préalable de l’Éditeur ou vendre, concéder une licence, louer, ou transférer l’Application SHARLOCK à un tiers.</p>
                <p>Sauf indication expresse dans les présentes CGU, celles-ci n’accordent à aucune des parties, l’Utilisateur ou l’Éditeur, aucun droit, implicite ou autre, sur le contenu de l’autre ou sur la propriété intellectuelle de l’autre.</p>

                <h2>Marques déposées</h2>

                <p>Les noms de sociétés et de produits réels mentionnés dans le présent document peuvent être les marques de commerce de leurs propriétaires respectifs.</p>
                <p>Sauf indication expresse dans les présentes CGU, ces dernières n’accordent à aucune des parties, Utilisateur ou Éditeur, aucun droit, implicite ou autre, sur le contenu de l’autre ou sur l’une des marques de l’autre.</p>

                <h2>Informations de contact</h2>

                <p><a href="mailto:sharlock.contact@gmail.com">sharlock.contact@gmail.com</a></p>

                <h2>Annexes</h2>

                <p>Annexe 1 – Politique de confidentialité</p>
                <p>Annexe 2 – Politique d’utilisation des Cookies</p>

            </main>
        </>
    );
}

export default Cgu;
