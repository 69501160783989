import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import './StickyButton.css';

const StickyButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const redirectAppStore = async () => {

        if (isMobile) {
            window.location.href = "https://apps.apple.com/fr/app/sharlock/id1633063767"
        }
        else {
            window.open("https://apps.apple.com/fr/app/sharlock/id1633063767", '_blank').focus();
        }

    }

    const redirectPlayStore = async () => {

        if (isMobile) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.sharlock.android"
        }
        else {
            window.open("https://play.google.com/store/apps/details?id=com.sharlock.android", '_blank').focus();
        }

    }

    const isMobileIosOrAndroid = async () => {

        var iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false);
        //iOS = true;
        if (iOS) {
            redirectAppStore()
        }

        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        if (isAndroid) {
            redirectPlayStore()
        }

    }
    
    const handleScroll = () => {
        const currentScrollPosition = window.pageYOffset;
        if (currentScrollPosition > window.innerHeight && currentScrollPosition) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleResize = () => {
        if (window.innerWidth <= 767) {
            window.addEventListener('scroll', handleScroll);
        } else {
            window.removeEventListener('scroll', handleScroll);
        }
    };

    useEffect(() => {
        // Call handleResize initially to set the scroll event listener if needed
        handleResize();

        // Attach the resize event listener
        window.addEventListener('resize', handleResize);

        // Clean up both event listeners on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures the effect only runs on mount and unmount

    return (
        <button className={`sticky-button ${isVisible ? 'visible' : 'hidden'}`} onClick={isMobileIosOrAndroid}>
            Télécharger l'application
        </button>
    );
};

export default StickyButton;
