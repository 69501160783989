import React from 'react';
import LazyLoadImage from "../components/LazyLoadingImage"

/****** Images ********/
import gemsCard from "../assets/images/gems-card.png"
import goodIdea from "../assets/images/good-idea.png"



const FindMyWayAmongGems = () => {

    return (
        <div className="gems-container">
            <div className="gems-wrapp-card">
                <div className="gems-card">
                    <div className="gems-card-content">
                        <h2 className="main-title" style={{ color: "white", textAlign: "left" }}>Mais comment m’y retrouver parmi toutes ces <span className="whitespan">pépites ?</span></h2>
                        <div className="gems-card-text">
                            <p className="text" style={{ color: "white" }}>Sur Sharlock, tu peux t’organiser facilement :</p>

                            <p className="text" style={{ color: "white" }}><strong>Ajoute</strong> les marques qui te plaisent à ton espace perso et crée des shopping listes. C’est comme des playlist mais avec des marques.</p>

                            <p className="text" style={{ color: "white" }}><strong>Partage</strong> les à tes amis pour les faire profiter de tes découvertes et les encourager à être aussi responsable que toi !</p>
                            <div className="gems-good-idea">
                                <LazyLoadImage
                                    alt="Ampoule indiquant bonne idée"
                                    src={goodIdea}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="gems-card-image">
                        <LazyLoadImage
                            alt="Femme rigolant sur son téléphone en utilisant l'application Sharlock"
                            src={gemsCard}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default FindMyWayAmongGems;
