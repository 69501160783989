import React, { useState, useRef } from "react"

import emailjs from '@emailjs/browser';
import { Element } from 'react-scroll'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { Bounce } from "react-activity";
import "react-activity/dist/library.css";
import { isMobile } from 'react-device-detect';
import LazyLoadImage from "../components/LazyLoadingImage"

/****** ICON ******/
import { IoPaperPlane } from "react-icons/io5";

/********* IMG *********/
import contactImg from "../assets/images/contact-img.png"

const ContactUs = () => {
    const form = useRef();
    const [email, setEmail] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [company, setCompany] = useState("")
    const [phone, setPhone] = useState("")
    const [isEmailSend, setEmailSend] = useState(false)
    const [emailProcess, setEmailProcess] = useState(false)

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const sendEmail = (e) => {
        e.preventDefault();
        setEmailProcess(true)
        emailjs.sendForm('service_vbyonnh', 'template_69e2dh7', form.current, 'Ewwk2tSDTTdR-fiAw')
            .then((result) => {
                if (result.status === 200) {

                    setEmailSend(true)
                }
            }).catch((err) => {

                alert("Une erreur s'est produite lors de l'envoi du mail")
                setEmailProcess(false)
            });
    };

    return (

        <Element name="contact" id="contact" className="contact-container">
            <div className="contact-content">
                <h2 className="main-title">Tu es une <span className="purplespan">marque</span> et tu souhaites nous rejoindre ?</h2>

                {!isEmailSend && !emailProcess ?

                    <form ref={form} onSubmit={sendEmail} className="contact-form">

                        <div className="contact-form-content">
                            <div className="contact-left-column">
                                <input
                                    name="user_email"
                                    type="email"
                                    placeholder="Email"
                                    className={regex.test(email) === false && email !== "" ? "contact-input-error" : "contact-input"}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.currentTarget.value);
                                    }}
                                    aria-label="email"
                                />

                                <div className="contact-form-row">

                                    <input
                                        name="user_lastname"
                                        type="text"
                                        placeholder="Nom"
                                        className="contact-input"
                                        value={lastname}
                                        onChange={(e) => {
                                            setLastname(e.currentTarget.value);
                                        }}
                                        aria-label="Nom"
                                    />

                                    <input
                                        name="user_firstname"
                                        type="text"
                                        placeholder="Prénom"
                                        className="contact-input"
                                        value={firstname}
                                        onChange={(e) => {
                                            setFirstname(e.currentTarget.value);
                                        }}
                                        aria-label="Prénom"
                                    />
                                </div>

                                <input
                                    name="user_company"
                                    type="text"
                                    placeholder="Marque"
                                    className="contact-input"
                                    value={company}
                                    onChange={(e) => {
                                        setCompany(e.currentTarget.value);
                                    }}
                                    aria-label="nom de l'entreprise"
                                />

                                <input
                                    name="user_phone"
                                    type="text"
                                    placeholder="Numéro de téléphone"
                                    className="contact-input"
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.currentTarget.value);
                                    }}
                                    aria-label="numéro de téléphone"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                />

                                {email === "" || regex.test(email) === false || firstname === "" || lastname === "" || company === "" || phone === "" ?
                                    <div className="contact-submit-btn-desactivate">
                                        <p>Envoyer</p>
                                        <IoPaperPlane className="contact-submit-svg-desactivated" />
                                    </div>
                                    :
                                    <div className="contact-submit-btn">
                                        <input type="submit" value="ENVOYER" />
                                        <IoPaperPlane className="contact-submit-svg" />
                                    </div>
                                }

                            </div>

                            <div className="contact-right-column">
                                <LazyLoadImage
                                    alt={"Un chien en sweat à capuche qui vous regarde et dit merci"}
                                    src={contactImg}
                                />
                            </div>

                        </div>

                    </form>

                    : emailProcess && isEmailSend ?

                        <>
                            <Player
                                autoplay
                                speed={1}
                                src="https://assets3.lottiefiles.com/packages/lf20_OMBA1g.json"
                                className="contact-animation-sending-email"
                            >
                                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                            </Player>
                            <p className="contact-form-send">Le formulaire a été envoyé</p>
                        </>

                        :

                        <div className="contact-form-bounce">
                            <Bounce color="#AA80F9" size={isMobile ? 30 : 50} speed={0.8} />
                        </div>
                }

            </div>
        </Element>
    );
}

export default ContactUs;
