import React from 'react';
import { HiStar } from "react-icons/hi";
import LazyLoadImage from "../components/LazyLoadingImage"

/***** IMAGES  *******/
import RatingProfil1 from "../assets/images/rating-profil-1.png"
import RatingProfil2 from "../assets/images/rating-profil-2.png"

const Rating = () => {

    const reviews = [
        {
            id: 1,
            name: "Julien",
            title: "Je valide !",
            text: "Agréable découverte. J’ai découvert déja 2 marques pour mes enfants, c’est franchement génial. Hâte de voir les prochaines marques arrivées.",
            profil: RatingProfil1
        },
        {
            id: 2,
            name: "Myriam",
            title: "Enfin",
            text: "Enfin une application qui regroupe les marques engagées. On achète en connaissance de cause. Cela me réconcilie avec le web !",
            profil: RatingProfil2
        },
        {
            id: 3,
            name: "Nicolas",
            title: "Indispensable",
            text: "Très bonne application pour une consommation responsable. Indispensable pour tous ceux qui ont une conscience écologique. Je recommande vivement.",
            profil: RatingProfil1
        },
    ];

    return (
        <div className="rating-container">
            <h2 className="main-title" style={{ color: "white" }}>Ils en parlent <span className="whitespan">mieux</span> que nous</h2>

            <div className="rating-slider-container">
                {reviews.map((review) => (

                    <div key={review.id} className="rating-slider">
                        <div className="rating-card">
                            <div className="rating-stars">
                                <HiStar />
                                <HiStar />
                                <HiStar />
                                <HiStar />
                                <HiStar />
                            </div>
                            <p className="rating-title">{review.title}</p>
                            <p className="rating-text">{review.text}</p>

                            <div className="rating-identity">
                                <LazyLoadImage
                                    alt="Profil de l'utilisateur"
                                    src={review.profil}
                                />
                                <p className="rating-identity-name">{review.name}</p>
                            </div>
                        </div>
                    </div>

                ))}
            </div>


        </div>
    );
}

export default Rating;
