import React from "react"
import "./Home.css"
import { isMobile } from 'react-device-detect';

import LazyLoadImage from "../components/LazyLoadingImage"

/* IMG ***********/
import GooglePlay from "../assets/images/btn/playstore_btn.png"
import AppStore from "../assets/images/btn/appstore_btn.png"
import IntroHeader from "../assets/images/intro-header.png"

const HomeHeader = () => {

    const redirectAppStore = async () => {

        if (isMobile) {
            window.location.href = "https://apps.apple.com/fr/app/sharlock/id1633063767"
        }
        else {
            window.open("https://apps.apple.com/fr/app/sharlock/id1633063767", '_blank').focus();
        }

    }

    const redirectPlayStore = async () => {

        if (isMobile) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.sharlock.android"
        }
        else {
            window.open("https://play.google.com/store/apps/details?id=com.sharlock.android", '_blank').focus();
        }

    }

    return (
        <div className="home-header-wrapper">
            <div className="intro-header">

                <div className="intro-left-content">

                    <h1 className="intro-header-title">Trouver des marques <span className="intro-header-span">engagées</span> n’a jamais été aussi simple</h1>
                    <p className="intro-header-subtitle">L’app qui réunit les pépites françaises qui respectent la planète 🌎</p>

                    <div className="store-buttons-container">
                        <div className="store-button" onClick={redirectAppStore}>
                            <LazyLoadImage
                                alt="App store"
                                src={AppStore}
                            />

                        </div>

                        <div className="store-button" onClick={redirectPlayStore}>
                            <LazyLoadImage
                                alt="Google Play"
                                src={GooglePlay}
                            />
                        </div>
                    </div>

                </div>

                <div className="intro-right-content">
                    <LazyLoadImage
                        alt="Screenshoot de présentation de l'application mobile"
                        src={IntroHeader}
                    />
                </div>

            </div>

            <p className="intro-bottom-text">Déjà plus de 50 marques référencées</p>
        </div>
    );
}

export default HomeHeader;
