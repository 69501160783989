import React from 'react';
import LazyLoadImage from "../components/LazyLoadingImage"

const Environmentally = () => {

    return (
        <div className="environmentally-container">
            <div className="environmentally">
                <div className="environmentally-header">
                    <h2 className="main-title">Tu veux te <span className="bluespan">mettre au vert</span> mais tu ne sais pas comment faire pour trouver des marques responsables, vraiment responsables ?</h2>
                    <p className="text center">Sharlock est ton intermédiaire de confiance pour passer à une consommation engagée et stylée</p>
                </div>

                <div className="article-container">
                    <article className="environmentally-article">
                        <div className="environmentally-article-first-container">
                            <LazyLoadImage
                                alt="Menu pour filtrer les catégories"
                                src={require("../assets/images/mobile-app-nav-filtering-buttons.png")}
                            />
                            <LazyLoadImage
                                alt="Mode homme & mode femme"
                                src={require("../assets/images/mode-gender.png")}
                            />
                        </div>
                        <h3 className="second-title">De tout, Mais que du bon</h3>
                        <p className="text">De la mode à la beauté en passant par les enfants, la déco ou les sneakers, tout est sur Sharlock et tout est green.</p>
                    </article>

                    <article className="environmentally-article">
                        <div className="environmentally-article-second-container">
                            <LazyLoadImage
                                alt="Descriptifs des marques"
                                src={require("../assets/images/detailed-universe.png")}
                            />
                            <LazyLoadImage
                                alt="Awesome"
                                src={require("../assets/images/awesome.png")}
                            />
                        </div>
                        <h3 className="second-title">Un univers détaillé</h3>
                        <p className="text">Pour t’aider à sélectionner les marques qui te plaisent, Sharlock détaille pour chacune d’entre elle : ses engagements, sa mission et son histoire.</p>
                    </article>

                    <article className="environmentally-article">
                        <div className="environmentally-article-third-container">
                            <LazyLoadImage
                                alt="Homme en veste zippée bleue et verte portant des lunettes à monture noire"
                                src={require("../assets/images/hapiness-access.png")}
                            />
                        </div>
                        <h3 className="second-title">Un accès direct à ton bohneur</h3>
                        <p className="text">Si une marque t’a tapé dans l’oeil, accède directement à son site depuis Sharlock.</p>
                    </article>
                </div>

                <p className="environmentally-bottom-text">Sharlock sélectionne uniquement des marques qui prennent soin de la planète. Vous pouvez y aller les yeux fermés !</p>

            </div>
        </div>
    );

}

export default Environmentally;
