import './ShoppingList.css';
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { isMobile } from 'react-device-detect';
import Helmet from 'react-helmet';
import LazyLoadImage from "../components/LazyLoadingImage"

/********** FIREBASE ***************/
import { db, anonymousAuth } from "../firebase/config"
import { getDoc, getDocs, doc, collection, query } from "firebase/firestore"


/*************** IMG *****************************************/
import appStore from "../assets/images/btn/appstore_btn.png"
import googlePlay from "../assets/images/btn/playstore_btn.png"
import logo from "../assets/images/logo.png"
import IntroHeaderReduced from "../assets/images/intro-header-reduced.png"
import Environmentally from "./Environmentally"
import CategoriesBanner from "./CategoriesBanner"
import FindMyWayAmongGems from "./FindMyWayAmongGems"
import StayInform from "./StayInform"
import Rating from "./Rating"
import Instagram from "./Instagram"
import ContactUs from "./ContactUs"

const ShoppingList = () => {

    const [loaded, setLoaded] = useState(false)
    const [shoppingList, setShoppingList] = useState(null)
    const [showPanel, setShowPanel] = useState(false);
    const params = useParams()

    const Id = params.id

    const isMobileIosOrAndroid = async () => {

        var iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false);
        //iOS = true;
        if (iOS) {
            return "ios"
        }

        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        if (isAndroid) {
            return "android"
        }

    }

    const redirectAppStore = async () => {
        if (isMobile) {
            const OS = await isMobileIosOrAndroid()
            if (OS === "android") {
                window.location.href = `sharlock://ShoppingList/${Id}`

                setTimeout(function () {
                    window.location.href = "https://apps.apple.com/fr/app/sharlock/id1633063767";
                }, 10000);
            }
            if (OS === "ios") {
                //window.open("https://apps.apple.com/fr/app/sharlock/id1633063767", '_blank').focus();

                window.location.href = `sharlock://ShoppingList/${Id}`


                setTimeout(function () {
                    window.location.href = "https://apps.apple.com/fr/app/sharlock/id1633063767";
                }, 10000);

            }
        }
        else {
            window.location.href = "https://apps.apple.com/fr/app/sharlock/id1633063767"
        }

    }

    const redirectPlayStore = async () => {

        if (isMobile) {
            const OS = await isMobileIosOrAndroid()
            if (OS === "android") {
                window.location.href = `sharlock://ShoppingList/${Id}`

                setTimeout(function () {
                    window.location.href = "https://play.google.com/store/apps?hl=fr&gl=FR"
                }, 10000);
            }
            if (OS === "ios") {
                //window.open("https://apps.apple.com/fr/app/sharlock/id1633063767", '_blank').focus();

                window.location.href = `sharlock://ShoppingList/${Id}`


                setTimeout(function () {
                    window.location.href = "https://play.google.com/store/apps?hl=fr&gl=FR";
                }, 10000);

            }
        }
        else {
            window.location.href = "https://play.google.com/store/apps?hl=fr&gl=FR"
        }

    }

    const handleClose = () => { // close modal
        setShowPanel(false);
    };

    useEffect(() => {
        anonymousAuth.then(async () => {
            try {
                const docRef = await getDoc(doc(db, 'shoppingList', Id));
                if (docRef.data() === undefined) { // No ref found with the shopping-list ID
                    setLoaded(true);
                    return;
                }

                const mobileOS = await isMobileIosOrAndroid();
                if (mobileOS === "ios" || mobileOS === "android") {
                    window.location.href = `sharlock://ShoppingList/${Id}`;
                    return;
                }

                const [shoppingListRef, userRef] = await Promise.all([
                    getDoc(doc(db, 'users', docRef.data().owner, 'categories', docRef.data().categoryName)),
                    getDoc(doc(db, 'users', docRef.data().owner))
                ]);

                const shoppingListTemp = shoppingListRef.data();
                shoppingListTemp.owner = {
                    firstname: userRef.data().firstname,
                    lastname: userRef.data().lastname,
                    id: shoppingListRef.data().owner
                };
                shoppingListTemp.data = [];

                const allWebsitesInShoppingList = collection(db, `users/${docRef.data().owner}/categories/${docRef.data().categoryName}/websites`);
                const q = query(allWebsitesInShoppingList);
                const snapshot = await getDocs(q);

                const shoppingListData = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
                    const partnerRef = doc(db, 'partners', docSnapshot.data().id);
                    const partnerDoc = await getDoc(partnerRef);
                    return partnerDoc.data();
                }));

                shoppingListTemp.data = shoppingListData;
                setShoppingList(shoppingListTemp);
                setLoaded(true);
            } catch (err) {
                console.log(err);
            }
        }).catch((err) => console.log(err));
    }, []);

    return (

        <>
            <Modal
                open={showPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={false}
            >
                <Box className="shopping-list-modal-box">
                    <ClickAwayListener onClickAway={handleClose}>
                        <div className="shopping-list_modal_container">
                            <h2 className="shopping-list_modal_title">Télécharge l'application !</h2>
                            <p className="shopping-list_modal_text">La version web de Sharlock n’est pas encore disponible. Télécharge l’application pour pouvoir consulter et ajouter la shopping liste à ton espace personnel.</p>
                            <div className="mobile_link_button_container">
                                <div className="button_store_container" onClick={() => redirectAppStore()}>
                                    <img src={appStore} alt="App Store Button" />
                                </div>
                                <div className="button_store_container" onClick={() => redirectPlayStore()}>
                                    <img src={googlePlay} alt="Google Play Button" />
                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>
                </Box>
            </Modal>

            <div className="home-header-wrapper">
                <div className="intro-header">

                    <div className="intro-left-content">

                        <h1 className="intro-header-title">Trouver des marques <span className="intro-header-span">engagées</span> n’a jamais été aussi simple</h1>
                        <p className="intro-header-subtitle">L’app qui réunit les pépites françaises qui respectent la planète 🌎</p>

                    </div>

                    <div className="intro-right-content">
                        <LazyLoadImage
                            alt="Screenshoot de présentation de l'application mobile"
                            src={IntroHeaderReduced}
                        />
                    </div>

                </div>
            </div>

            <main className="shopping-list_main">
                {loaded && shoppingList !== null ?

                    <>
                        <Helmet>
                            <title>Sharlock - Shopping liste {shoppingList.name}</title>
                            <meta name='description' content={`Visionne ${shoppingList.name}, la shopping liste de ${shoppingList.owner.firstname} ${shoppingList.owner.lastname}`} />
                        </Helmet>
                        <section className="shopping-list_first_section">

                            <div className="shopping-list_details_container">
                                <div className="shopping-list_details">
                                    <h1 className="shopping-list_title">{shoppingList.name}</h1>
                                    <p className="shopping-list_subtitle">Shopping liste de {shoppingList.owner.firstname} {shoppingList.owner.lastname}</p>
                                </div>
                                <div>
                                    <button onClick={() => { setShowPanel(true) }} className="shopping-list_add_btn">Ajouter</button>
                                </div>
                            </div>

                            { /*  Card List */}
                            <div className="shopping-list_flatlist_websites">
                                {shoppingList.data.map((item) => {
                                    return (
                                        <div key={item.uid} className="shopping-list_card">

                                            <img src={item.picture} alt={item.name} className="shopping-list_card_img" />

                                            <div className="shopping-list_card_content">
                                                <p className="shopping-list_card_content_title">Shopping liste</p>
                                                <p className="shopping-list_card_content_name">{item.name}</p>
                                                <div className="shopping-list_card_content_share">
                                                    <p className="shopping-list_card_content_share_text">Partager</p>
                                                </div>
                                            </div>


                                        </div>
                                    )
                                })}
                            </div>

                        </section>
                    </>

                    : !loaded ?
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} style={{ height: window.innerHeight - 330 }}>
                            <CircularProgress />
                        </Box>

                        : null
                }

                {loaded && shoppingList === null ?
                    <div className="content" style={{ height: window.innerHeight - 280, paddingBottom: "3.438rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img src={logo} alt="logo" style={{ width: 80, height: 80, borderRadius: 50 }} />
                        <h1 className="page_not_found_title">Shopping liste introuvable</h1>
                        <a className="primary" href="/">Accueil</a>
                    </div>

                    : null
                }

            </main>

            <div style={{ backgroundColor: "#FEF7EA", paddingTop: "6.5%" }}>
                <Environmentally />
            </div>

            <CategoriesBanner />

            <FindMyWayAmongGems />

            <StayInform />

            <Rating />

            <Instagram />

            <ContactUs />
        </>

    );
}



export default ShoppingList;
