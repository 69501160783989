import React, { useEffect, useRef, useState } from 'react';
import LazyLoadImage from "../components/LazyLoadingImage"

const logos = [
    /* Row 1 */
    { id: 1, alt: 'Logo nemmes', src: require('../assets/images/slider/nemmes.png') },
    { id: 2, alt: 'Logo olly', src: require('../assets/images/slider/olly.png') },
    { id: 3, alt: 'Logo losanje', src: require('../assets/images/slider/losanje.png') },
    { id: 4, alt: 'Logo meeko', src: require("../assets/images/slider/meeko.png") },
    { id: 5, alt: 'Logo zeeta', src: require("../assets/images/slider/zeta.png") },
    { id: 6, alt: 'Logo inga', src: require("../assets/images/slider/inga.png") },
    { id: 7, alt: 'Logo minuit', src: require("../assets/images/slider/minuit.png") },
    { id: 8, alt: 'Logo tranquile emile', src: require("../assets/images/slider/tranquileemile.png") },
    /* Row 2 */
    { id: 9, alt: 'Logo albatras', src: require('../assets/images/slider/albatras.png') },
    { id: 10, alt: 'Logo amalthee', src: require('../assets/images/slider/amalthee.png') },
    { id: 11, alt: 'Logo azar/gang', src: require('../assets/images/slider/azar.png') },
    { id: 12, alt: 'Logo blasted', src: require("../assets/images/slider/blasted.png") },
    { id: 13, alt: 'Logo bott', src: require("../assets/images/slider/bott.png") },
    { id: 14, alt: 'Logo fenics shoes', src: require("../assets/images/slider/fenics.png") },
    { id: 15, alt: 'Logo funethic', src: require("../assets/images/slider/funethic.png") },
    { id: 16, alt: 'Logo graine de pastel', src: require("../assets/images/slider/grainedepastel.png") },
    /* Row 3 */
    { id: 17, alt: 'Logo homonoia', src: require('../assets/images/slider/homonoia.png') },
    { id: 18, alt: 'Logo irise-paris', src: require('../assets/images/slider/irise.png') },
    { id: 19, alt: 'Logo kwash', src: require('../assets/images/slider/kwash.png') },
    { id: 20, alt: 'Logo nêge paris', src: require("../assets/images/slider/negeparis.png") },
    { id: 21, alt: 'Logo neo by nature', src: require("../assets/images/slider/neobynature.png") },
    { id: 22, alt: 'Logo nin nin', src: require("../assets/images/slider/ninnin.png") },
    { id: 23, alt: 'Logo orijinal', src: require("../assets/images/slider/orijinal.png") },
    { id: 24, alt: 'Logo tomo clothing', src: require("../assets/images/slider/tomoclothing.png") },
];

const SliderLogo = () => {
    const [rowLogos, setRowLogos] = useState(logos.slice(0, 8));
    const [active, setActive] = useState(true); // Set the initial state to true
    const sliderLogoRef = useRef(null);

    // Function to update the row logos based on currentRow
    const updateRowLogos = (currentRow) => {
        const start = currentRow * 8;
        const end = start + 8;
        setRowLogos(logos.slice(start, end));
    };

    // Handle interval for slider rotation
    useEffect(() => {
        let currentRow = 1;
        const intervalId = setInterval(() => {
            updateRowLogos(currentRow);
            currentRow = (currentRow + 1) % 3;
            setActive(true);
            setTimeout(() => setActive(false), 3500);
        }, 4000);

        return () => clearInterval(intervalId);
    }, []);

    // Deactivate the initial active state after the first animation duration
    useEffect(() => {
        const initialAnimationTimeout = setTimeout(() => {
            setActive(false);
        }, 3500);

        return () => clearTimeout(initialAnimationTimeout);
    }, []);

    // Add active class and set height when rowLogos updates
    useEffect(() => {
        if (rowLogos.length > 0 && sliderLogoRef.current) {
            sliderLogoRef.current.classList.add("active");
        }
    }, [rowLogos, sliderLogoRef]);

    return (
        <div className="slider-container">
            <div className={`slider-logo${active ? " active" : ""}`} ref={sliderLogoRef}>
                {rowLogos.map((logo) => (
                    <article className="slider-card" key={logo.id}>
                        <LazyLoadImage alt={logo.alt} src={logo.src} />
                    </article>
                ))}
            </div>
        </div>
    );
};

export default SliderLogo;