import React, { useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom";
import { Navigate } from "react-router";
import { scroller} from 'react-scroll'

/**************  ROUTES *************/
import Home from "./pages/Home";
import ShoppingList from "./pages/ShoppingList"
import Cgu from "./pages/Cgu"
import Privacy from "./pages/Privacy"
import Cookies from "./pages/Cookies"

import Header from "./components/Header"
import Footer from "./components/Footer"
import StickyButton from './components/StickyButton';
import CrispChat from './components/CrispChat';

function App() {
  const location = useLocation();

  const navigationProps = location.state;

  useEffect(() => {
    if (navigationProps !== null) {

      scroller.scrollTo(navigationProps.anchor, {
        smooth: true,
        duration: 1000,
        offset: -50
      });

    }

  }, [navigationProps]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/shopping-list/:id" element={<ShoppingList />} />
        <Route exact path="/condition-generales-d'utilisation" element={<Cgu />} />
        <Route exact path="/confidentialite" element={<Privacy />} />
        <Route exact path="/cookies" element={<Cookies />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Footer />
      <CrispChat />
      <StickyButton />

    </div>
  );
}

export default App;
