import React, { useEffect } from 'react';

const CrispChat = () => {

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "410406ef-04b7-409d-8513-2605e0bbcdae";

    const script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <React.Fragment />
  );
};

export default CrispChat;