import React from 'react';
import "./Footer.css"
import { Icon } from '@iconify/react';
import { Link, scroller } from 'react-scroll';
import { Link as Redirect, useNavigate } from "react-router-dom";

import instagramIcon from '@iconify-icons/simple-icons/instagram';
import tiktokIcon from '@iconify-icons/simple-icons/tiktok';
import linkedinIcon from '@iconify-icons/simple-icons/linkedin';

const Footer = () => {
    const navigate = useNavigate();

    const openCrispChat = (event) => {
        event.preventDefault();

        if (window.$crisp) {
            window.$crisp.push(["do", "chat:open"]);
            window.$crisp.push(["do", "chat:show"]);
        }
    };

    const handleScroll = (to) => {
        const element = document.getElementById(to);

        if (element) {
            scroller.scrollTo(to, {
                smooth: true,
                duration: 500,
                offset: 0, // adjust the offset as needed
                spy: {
                    callback: (currentPosition) => {
                        if (currentPosition === null) {
                            console.log("if")
                        }
                    },
                },
            });
        } else {
            const data = { anchor: "contact" };
            navigate("/", { state: data });
        }
    }

    return (
        <footer>
            <div className="footer-wrapper">
                <div className="footer-left">
                    <div className="footer-top">
                        <div className="footer-parent-title" onClick={() => window.location.href = "/"}>
                            <p className="footer-title">Sharlock</p>
                        </div>

                        <div className="footer-social-media">
                            <a href="https://www.instagram.com/sharlock_app" target="blank">
                                <Icon icon={instagramIcon} className="footer-icon" />
                            </a>
                            <a href="https://www.tiktok.com/@sharlock_app" target="blank">
                                <Icon icon={tiktokIcon} className="footer-icon" />
                            </a>
                            <a href="https://www.linkedin.com/company/sharlock" target="blank">
                                <Icon icon={linkedinIcon} className="footer-icon" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="footer-right">
                    <div className="footer-middle">
                        <div className="footer-col">
                            <p className="footer-middle-title">Aide</p>
                            <a href="#" onClick={openCrispChat} className="footer-link">Nous contacter</a>
                        </div>
                        <div className="footer-col">
                            <p className="footer-middle-title">Marque</p>
                            <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-25} duration={1000} className="footer-link" onClick={() => handleScroll("contact")}>
                                Nous rejoindre
                            </Link>
                        </div>
                    </div>

                    <div className="footer-bottom">
                        <Redirect to="/condition-generales-d'utilisation" aria-label="Mention légales & CGU" className="footer-nav-link">Mention légales & CGU</Redirect>
                        <Redirect to="/confidentialite" aria-label="Confidentialité" className="footer-nav-link">Confidentialité</Redirect>
                        <Redirect to="/cookies" aria-label="Cookies" className="footer-nav-link">Cookies</Redirect>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
