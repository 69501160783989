import React from 'react';
import LazyLoadImage from "../components/LazyLoadingImage"

/***** IMAGES  *******/
import Wow from "../assets/images/wow.png"
import StayInformImg from "../assets/images/stay-inform.png"


const StayInform = () => {
    return (
        <div className="stay-inform-container">
            <div className="stay-inform-card">
                <div className="stay-inform-card-content">
                    <h2 className="main-title" style={{ textAlign: "left" }}>Reste <span className="bluespan">informer</span> pour être sur de ne rien louper !</h2>
                    <div className="stay-inform-card-text">
                        <p className="text">Tu es au courant des actus/promotions de tes marques préférées, non pas sur ta boite mail mais directement sur Sharlock !
                            Swipe à droite pour en profiter !
                        </p>

                        <LazyLoadImage
                            alt="WOW !"
                            src={Wow}
                        />
                    </div>
                    <p className="second-text">PSSSSS : Il y a même des offres exclusives !</p>
                </div>

                <div className="stay-inform-card-image">
                    <LazyLoadImage
                        alt="Un exemple de swipe d'offres sur l'application Sharlock"
                        src={StayInformImg}
                    />
                </div>
            </div>
        </div>
    );
}

export default StayInform;
